import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import nykka from "../../Assets/Projects/Nykka.png";
import blufly from "../../Assets/Projects/Blufly.png";
import dot from "../../Assets/Projects/Dotandkey.png";
import tim from "../../Assets/Projects/Timely.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "space-between", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nykka}
              isBlog={false}
              title="Nykaa Clone"
              description="Nykaa is an Indian e-commerce company, founded by Falguni Nayar in 2012 and headquartered in Mumbai. It sells beauty, wellness and fashion products across websites, mobile apps and 100+ offline stores."
              list1="HTML"
              list2="CSS"
              list3="Javascript"
              ghLink="https://github.com/faisal1205/Nykaa-Clone-Project"
              demoLink="https://unique-malasada-feb4b6.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={blufly}
              isBlog={false}
              title="BluFly Clone"
              description="Bluefly is a pioneer in offering the best in designer brands and fashion trends at a value customers love in an online environment that is fun to visit and easy to navigate. This website is based e-commerce and deliver product for both mens and womens"
              list1="HTML"
              list2="CSS"
              list3="Javascript"
              ghLink="https://github.com/SPinfluencers/BlueFly"
              demoLink="https://zesty-dragon-ce71c1.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dot}
              isBlog={false}
              title="Dot and Key Clone"
              description="Dot and Key is an award-winning skincare company established to transform the idea of 'skincare' into a fun, easy, and acceptable notion. A fresh take on skincare. One that's founded on the understanding that everyone is unique."
              list1="React"
              list2="Redux"
              list3="ChakraUI/Bootstrap"
              ghLink="https://github.com/Bhavesh2302/Dot-and-key-project-unit-4"
              demoLink="https://dotandkey2.netlify.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tim}
              isBlog={false}
              title="Timely Clone"
              description="Timelyapp is centralized automated system that makes it easy for teams to work on all aspects of their programs and projects to ensure efficiency and productivity Timely helps in Log work hours, track project time and create weekly timesheets with automatic time tracking."
              list1="React"
              list2="Redux"
              list3="Express/Mongodb"
              ghLink="https://github.com/riyagshah/Timelyapp"
              demoLink="https://timely-clone.netlify.app/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
